// import node modules
import React, { Component } from "react";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import {  Link } from "gatsby";
import NotificationCookie from "../Notification/NotificationCookie";
import { SocialIcon } from "react-social-icons"

// import constant files
import {
  WHERE_TO_BUY, FAQS, CONTACT_US, PRIVACY_POLICY, TERMS_OF_USE, ABOUT_OUR_ADS
} from "../../constants/pages/HeaderFooter.constant";

const socialStyle = { height: 50, width: 50 }
const socialStyleMobile = { height: 30, width: 30 }

class Footer extends Component {

  
  render() {
    return (
      <section>
        <div className="gagl-footer-lg d-none d-lg-block">
          <Row className="m-0">
            <Col lg={12} className="gagl-footers">
              <div 
              className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/where-to-buy/">{WHERE_TO_BUY}</Link>
              </div>
              <div className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/faqs/">{FAQS}</Link>
              </div>
              <div className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/contact-us/">{CONTACT_US}</Link>
              </div>
              <div className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/privacy-policy/">{PRIVACY_POLICY}</Link>
              </div>
              <div className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/terms-of-use/">{TERMS_OF_USE}</Link>
              </div>
              <div className="nlk mx-lg-5 mx-md-0 py-lg-4 py-md-1  d-inline-block" 
              >
                <Link to="/about-our-ads/">{ABOUT_OUR_ADS}</Link>
              </div>
            </Col>
            <Col className="footer-social pb-2">
              <SocialIcon
                  url="http://facebook.com/galileosalame"
                  style={socialStyle}
                  target="_blank"
              />
              <SocialIcon
                  url="http://twitter.com/galileosalame"
                  style={socialStyle}
                  target="_blank"
              />
              <SocialIcon
                  url="https://www.instagram.com/galileosalame/"
                  style={socialStyle}
                  target="_blank"
              />
            </Col>
            <Col lg={12}>
              <small 
                className="gagl-copyright-lg">
                <a
                  className="gagl-pointer"
                  href="https://app.keysurvey.com/f/1463959/4ffd/"
                  target="_blank">
                  Do Not Sell My Personal Information
                </a>.
                  California Transparency in Supply Chains Act Disclosure&nbsp;
                <a 
                  className="gagl-pointer"
                  href="/disclosure/Galileo CA Transparency Disclosure.pdf"
                  target="_blank">
                  (PDF)
                </a>. 
                  © Copyright { new Date().getFullYear() } Galileo{/* <sup>®</sup> */} Foods. All rights reserved.
              </small>
            </Col>
          </Row>
        </div>
        <div className="gagl-footer-xsmd d-none d-block d-sm-block d-md-block d-lg-none mt-2 mb-2">
          <Row className="m-0">
            <Col className="gagl-footer-left">
              <Row className="pl-2 pt-2 pr-2 pb-2 m-0"
              >
                <Link to="/where-to-buy/">{WHERE_TO_BUY}</Link>
              </Row>
              <Row className="pl-2 pt-2 pr-2 pb-2 m-0"
              >
                <Link to="/faqs/">{FAQS}</Link>
              </Row>
              <Row className="pl-2 pt-2 pr-2 pb-2 m-0"
              >
                <Link to="/contact-us/">{CONTACT_US}</Link>
              </Row>
              <Col className="footer-social pb-2 ml-0 px-0">
                <SocialIcon
                    url="http://facebook.com/galileosalame"
                    style={socialStyleMobile}
                    target="_blank"
                />
                <SocialIcon
                    url="http://twitter.com/galileosalame"
                    style={socialStyleMobile}
                    target="_blank"
                />
                <SocialIcon
                    url="https://www.instagram.com/galileosalame/"
                    style={socialStyleMobile}
                    target="_blank"
                />
              </Col>
            </Col>
            <Col className="gagl-footer-right">
              <Row className="pl-4 pt-2 pr-2 pb-2 m-0 gagl-borderleft-gray"
              >
                <Link to="/privacy-policy/">{PRIVACY_POLICY}</Link>
              </Row>
              <Row className="pl-4 pt-2 pr-2 pb-2 m-0 gagl-borderleft-gray"
              >
                <Link to="/terms-of-use/">{TERMS_OF_USE}</Link>
              </Row>
              <Row className="pl-4 pt-2 pr-2 pb-2 m-0 gagl-borderleft-gray"
              >
                <Link to="/about-our-ads/">{ABOUT_OUR_ADS}</Link>
              </Row>
              <Row className="pl-4 pt-2 pb-2 m-0 gagl-borderleft-gray gagl-copyright-xsmd">
                <small>
                  <a
                    className="gagl-pointer"
                    href="https://app.keysurvey.com/f/1463959/4ffd/"
                    target="_blank">
                      Do Not Sell My Personal Information.
                  </a> <br />
                    California Transparency in Supply Chains Act Disclosure&nbsp;
                  <a
                    className="gagl-pointer"
                    href="/disclosure/Galileo CA Transparency Disclosure.pdf"
                    target="_blank">
                      (PDF)
                  </a>. <br />
                    © Copyright { new Date().getFullYear() } Galileo{/* <sup>®</sup> */} Foods. All rights reserved.
                  </small>
              </Row>
            </Col>
          </Row>

        </div>

        <NotificationCookie className={"notification--gdpr"}
                  text="Your privacy is important to us. We use cookies to ensure you get the best experience on this website. For more information about how to control your cookies click learn more."
                  buttonText={"Accept"}
                  buttons={
                    <Link to={"/privacy-policy/"}>
                      <Button
                          color={"Primary"}
                          buttoncolor={"Primary"}
                          type="button"
                          className={`notification__btn ml-3 position-relative btn`}>
                        Learn More
                      </Button>
                    </Link>
                  }
    />
      </section>
    );
  }
}

export default Footer;
