// import node modules
import React, { Component } from "react";
import {
  Row,
  Col
} from "reactstrap";
import { navigate, Link } from "gatsby";

// import assets
import galileoLogo from "../../assets/images/galileo-logo.png";

// import constant files
import {
  HOME, WHERE_TO_BUY, OUR_STORY, PRODUCTS, RECIPES,
  FAQS, CONTACT_US
} from "../../constants/pages/HeaderFooter.constant";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }
  pageNavigate = pagePath => {
    navigate(pagePath);
  };
  menuClick = (args) => {
    this.setState({ showMenu: !args });
  };
  render() {
    const { showMenu } = this.state;
    return (
      <section className="gagl-fixed-header">
        <div className="gagl-top-header">
          <Row className="m-0">
            <Col className="pr-0 text-center">
              <img src={galileoLogo} className="gagl-brand-logo" alt="galileo salame logo" onClick={() => { this.pageNavigate("/"); }}/>
              <div className={showMenu ? 'fa fa-times float-right gagl-hamburger-menu' : 'fa fa-bars float-right gagl-hamburger-menu'}
                onClick={() => { this.menuClick(showMenu) }} aria-label="Menu"></div>
            </Col>
          </Row>
        </div>
        <div className={showMenu ? 'gagl-headings gagl-sub-header gagl-header-height' : 'd-none'}>
          <Row className="gagl-bg">
            <Col xs={12} md={12} lg className="p-0">
              <Link className="text-center gagl-secondary-menu gagl-home-menu" to="/">{HOME}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link className="text-center gagl-secondary-menu" to="/where-to-buy/">{WHERE_TO_BUY}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link className="text-center gagl-secondary-menu" to="/our-story/">{OUR_STORY}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link className="text-center gagl-secondary-menu" to="/our-products/">{PRODUCTS}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link to="/recipes/" className="text-center gagl-secondary-menu">{RECIPES}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link to="/faqs/" className="text-center gagl-secondary-menu">{FAQS}</Link>
            </Col>
            <Col xs={12} md={12} lg className="p-0">
              <Link to="/contact-us/" className="text-center gagl-secondary-menu"><span>{CONTACT_US}</span></Link>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default Header;
