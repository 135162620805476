import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { upperFirst } from "lodash"
import classNames from "classnames"
import Header from "../Header/Header.component";
import Footer from "../Footer/Footer.component";

// import styles
import "./Layout.component.scss";

const TemplateWrapper = ({
  pageTitle,
  metaTitle,
  metaDescription,
  metaKeywords,
  className,
  children,
  schema
}) => {
  const title = metaTitle ? metaTitle : pageTitle ? upperFirst(pageTitle) : ""
  const classes = classNames(className)
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Helmet
            htmlAttributes={{
              lang: "en"
            }}
            title={`${title ? title + " | " : ""}${
              data.site.siteMetadata.title
            }`}
            meta={[
              { name: "description", content: metaDescription },
              { name: "keywords", content: metaKeywords },
              { name: "theme-color", content: "#495057" }
            ]}
          >
            {schema &&
              <script type="application/ld+json">
                {JSON.stringify(schema)}
              </script>
            }
				
          </Helmet>
          <Header/>
            <section className="gagl-main-wrapper">
              <main className={classes}>{children}</main>
            </section>
          <Footer/>
        </React.Fragment>
      )}
    />
  )
}

export default TemplateWrapper
