export const HOME = `home`;
export const WHERE_TO_BUY = 'where to buy';
export const OUR_STORY = `our story`;
export const PRODUCTS = `products`;
export const RECIPES = `recipes`;
export const FAQS = `faqs`;
export const CONTACT_US = `contact us`;
export const PRIVACY_POLICY = 'privacy policy';
export const TERMS_OF_USE = 'terms of use';
export const ABOUT_OUR_ADS = 'about our ads';
export const COPYRIGHT_TEXT = 'California Transparency in Supply Chains Act Disclosure. © Copyright Tyson Foods. All rights reserved.';